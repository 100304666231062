import type { FAQType } from '@kanbu/schema';
import { createFAQSchema, updateFAQSchema } from '@kanbu/schema/contracts';
import { useNavigate, Navigate } from '@tanstack/react-router';
import type { TypedFormState } from '@utima/ui-informed';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { CRUDForm } from '@/components/form/CRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

import { useFAQMutation } from './useFAQMutation';

export type FAQFormProps = {
  data?: FAQType;
};

export const FAQForm = memo(function FAQForm({ data }: FAQFormProps) {
  const { t } = useTranslation(['glossary']);
  const navigate = useNavigate();
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove, isSuccess: isRemoved },
    update: { mutateAsync: update },
  } = useFAQMutation();

  const { schema: detailsSchema } = useFieldsSchema([
    {
      name: 'title',
      label: t('glossary:labels.name'),
      required: true,
    },
    {
      name: 'question',
      label: t('glossary:labels.question'),
      required: true,
    },
    {
      name: 'answer',
      label: t('glossary:labels.answer'),
      required: true,
    },
  ]);

  const handleDelete = useCallback(async () => {
    if (data) {
      await remove({ id: data.id });
    }
  }, [data, remove]);

  const handleSubmit = useCallback(
    async (formState: TypedFormState<FAQType | undefined>) => {
      const { values } = formState;

      if (!values) {
        return;
      }

      if (data) {
        await update(
          updateFAQSchema.parse({
            ...values,
            id: data.id,
          }),
        );
      } else {
        const data = await create(createFAQSchema.parse(values));

        // Redirect to edit page on success
        if (data) {
          navigate({
            to: '/faqs/$faqId/edit',
            params: { faqId: data.id },
          });
        }
      }
    },
    [create, data, navigate, update],
  );

  // Redirect on removal
  if (isRemoved) {
    return <Navigate to='/faqs' />;
  }

  return (
    <CRUDForm
      initialValues={data}
      zodSchema={data ? createFAQSchema : updateFAQSchema}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    >
      <CRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </CRUDForm.Col>
      <CRUDForm.Col />
      <CRUDForm.Col>
        <MetaFields data={data} />
      </CRUDForm.Col>
    </CRUDForm>
  );
});
