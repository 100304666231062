import type { FAQType } from '@kanbu/schema';
import { createSearchExpression } from '@kanbu/schema/shared';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewButton } from '@/components/actionButtons/AddNewButton';
import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import {
  DataTable,
  type DataTableQuery,
} from '@/components/dataTable/DataTable';
import type { DataTableRowHandler } from '@/components/dataTable/dataTableTypes';
import { useFAQsCols } from '@/features/faqs/useFAQsCols';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/faqs/')({
  component: FaqsPage,
});

export function FaqsPage() {
  const { t } = useTranslation(['globals', 'faqs']);
  const columns = useFAQsCols();
  const navigate = useNavigate();
  const breadCrumbItems = useBreadCrumb(
    [{ label: t('globals:routeNames.faqs'), to: '/faqs' }],
    [t],
  );

  const handleQuery: DataTableQuery<FAQType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.faqs.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        where: {
          searchable: { $like: createSearchExpression(search) },
        },
        orderBy: sort,
      });

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [],
  );

  const handleRow: DataTableRowHandler<FAQType> = useCallback(
    (event, row) => {
      navigate({
        to: '/faqs/$faqId/edit',
        params: {
          faqId: row.original.id,
        },
      });
    },
    [navigate],
  );

  return (
    <>
      <BreadCrumb items={breadCrumbItems} title={t('faqs:texts.manage')}>
        <AddNewButton to='/faqs/create'>
          {t('faqs:actions.create')}
        </AddNewButton>
      </BreadCrumb>
      <DataTable
        columns={columns}
        queryKey={getQueryKey(trpc.faqs.findAll)}
        onQueryFn={handleQuery}
        onRow={handleRow}
        showSearch
      />
    </>
  );
}
