import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { FAQForm } from '@/features/faqs/FAQForm';

export const Route = createFileRoute('/_baseLayout/faqs/create')({
  component: FAQsCreatePage,
});

export function FAQsCreatePage() {
  const { t } = useTranslation(['globals', 'faqs']);
  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('globals:routeNames.faqs'), to: '/faqs' },
      { label: t('faqs:texts.create'), to: '/faqs/create' },
    ],
    [t],
  );

  return (
    <>
      <BreadCrumb items={breadCrumbItems} title={t('faqs:texts.create')} />
      <FAQForm />
    </>
  );
}
