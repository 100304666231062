import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export type AgentConfigFieldsProps = {};

export const AgentConfigFields = memo(
  function AgentConfigFields({}: AgentConfigFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums']);
    const { schema } = useFieldsSchema([
      {
        name: 'agentConfig.systemPrompt',
        label: t('glossary:labels.systemPrompt'),
        type: 'text',
        uiProps: {
          rows: 20,
        },
        uiControl: 'textarea',
        required: true,
        defaultValue: 'You are an AI assistant.',
      },
      {
        name: 'agentConfig.tone',
        label: t('glossary:labels.tone'),
        type: 'text',
        uiControl: 'textarea',
        required: true,
        defaultValue: 'Professional, kind, friendly, helpful',
      },
      {
        name: 'agentConfig.language',
        label: t('glossary:labels.language'),
        required: true,
        defaultValue: 'Czech',
      },
      {
        name: 'agentConfig.rewriteRetries',
        label: t('glossary:labels.rewriteRetries'),
        type: 'number',
        defaultValue: 1,
      },
      {
        name: 'agentConfig.summaryThreshold',
        label: t('glossary:labels.summaryThreshold'),
        type: 'number',
        defaultValue: 6,
      },
      {
        name: 'agentConfig.retrievalCount',
        label: t('glossary:labels.retrievalCount'),
        type: 'number',
        defaultValue: 20,
      },
      {
        name: 'agentConfig.rerankCount',
        label: t('glossary:labels.rerankCount'),
        type: 'number',
        defaultValue: 5,
      },
      {
        name: 'agentConfig.minScore',
        label: t('glossary:labels.minScore'),
        type: 'number',
        defaultValue: 0.05,
      },
    ]);

    return (
      <Card title={t('glossary:labels.agentConfig')}>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
