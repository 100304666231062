import { z } from 'zod';

import { baseEntitySchema } from './baseContract';

export const sourceDocumentPartSchema = baseEntitySchema.extend({
  content: z.string(),
  metadata: z.any(),
  parent: z.string().uuid(),
});

export const createSourceDocumentPartSchema = sourceDocumentPartSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

export const updateSourceDocumentPartSchema = sourceDocumentPartSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type SourceDocumentPartDTO = z.infer<typeof sourceDocumentPartSchema>;
export type CreateSourceDocumentPartDTO = z.infer<
  typeof createSourceDocumentPartSchema
>;
export type UpdateSourceDocumentPartDTO = z.infer<
  typeof updateSourceDocumentPartSchema
>;
