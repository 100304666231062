import type { ThreadType } from '@kanbu/schema';
import { createSearchExpression } from '@kanbu/schema/shared';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { ChatDrawer } from '@/components/chat/ChatDrawer';
import {
  DataTable,
  type DataTableQuery,
  type DataTableRowHandler,
} from '@/components/dataTable/DataTable';
import { useThreadsCols } from '@/features/threads/useThreadsCols';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute(
  '/_baseLayout/chat-users/$chatUserId/threads/',
)({
  component: ChatUserThreadsPage,
});

export function ChatUserThreadsPage() {
  const { chatUserId } = Route.useParams();
  const { t } = useTranslation(['globals', 'chatUsers', 'threads']);
  const columns = useThreadsCols();
  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('globals:routeNames.chatUsers'), to: '/chat-users' },
      {
        label: chatUserId,
        to: '/chat-users/$chatUserId/threads',
      },
    ],
    [t],
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [thread, setThread] = useState<ThreadType | null>(null);

  const handleQuery: DataTableQuery<ThreadType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.threads.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        populate: ['chatMessageCount', 'messages'],
        where: {
          chatUser: {
            id: chatUserId,
          },
          ...(search
            ? { searchable: { $like: createSearchExpression(search) } }
            : {}),
        },
        orderBy: sort,
      });

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [chatUserId],
  );

  const handleRow: DataTableRowHandler<ThreadType> = useCallback(
    (event, row) => {
      setIsDrawerOpen(true);
      setThread(row.original);
    },
    [],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={`${t('threads:texts.manage')} - ${chatUserId}`}
      />
      <DataTable
        columns={columns}
        queryKey={[...getQueryKey(trpc.threads.findAll), chatUserId]}
        onQueryFn={handleQuery}
        onRow={handleRow}
        showSearch
      />
      <ChatDrawer
        open={isDrawerOpen}
        onOpenChange={setIsDrawerOpen}
        thread={thread}
      />
    </>
  );
}
