import type { SourceDocumentPartType } from '@kanbu/schema';
import { createColumnHelper } from '@tanstack/react-table';
import { marked } from 'marked';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';

import { useSourceDocumentPartMutation } from './useSourceDocumentPartMutation';

const columnHelper = createColumnHelper<SourceDocumentPartType>();

export function useSourceDocumentPartsCols() {
  const { t } = useTranslation(['glossary']);
  const {
    remove: { mutateAsync: remove },
  } = useSourceDocumentPartMutation();

  return useMemo(
    () => [
      columnHelper.accessor(row => row.content, {
        id: 'content',
        header: t('glossary:labels.content'),
        cell: ({ row }) => (
          <div>
            <Card className='bg-secondary/25 font-mono text-xs'>
              {Object.entries(row.original.metadata).map(([key, value]) => (
                <div key={key}>
                  <span className='font-bold'>{key}:</span> {value as string}
                </div>
              ))}
            </Card>
            <div
              className='markdown-wrapper'
              dangerouslySetInnerHTML={{
                __html: marked(row.original.content ?? '', {
                  async: false,
                }),
              }}
            />
          </div>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: t('glossary:labels.actions'),
        cell: ({ row }) => (
          <ActionsCell onDelete={() => remove({ id: row.original.id })} />
        ),
        meta: {
          align: 'center',
        },
      }),
    ],
    [remove, t],
  );
}
