import type { ChatType } from '@kanbu/schema';
import {
  generateEmbeddingsSchema,
  type GenerateEmbeddings,
} from '@kanbu/schema/contracts';
import { Button, Dialog, Separator, toast } from '@utima/ui';
import type { TypedFormState } from '@utima/ui-informed';
import type { FormApi } from 'informed';
import { Box, FileStack, ScrollText } from 'lucide-react';
import { memo, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { DisplayValue } from '@/components/displayValue/DisplayValue';
import { BasicForm } from '@/components/form/BasicForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { trpc } from '@/services/trpc';

export type EmbeddingsMetaFieldsProps = {
  data: ChatType | undefined;
};

export const EmbeddingsMetaFields = memo(function EmbeddingsMetaFields({
  data,
}: EmbeddingsMetaFieldsProps) {
  const { t } = useTranslation(['glossary', 'chats']);
  const formApi = useRef<FormApi>();
  const [isOpen, setIsOpen] = useState(false);
  const { mutateAsync, isPending } = trpc.embedder.generate.useMutation();

  // Init default values
  const initialValues = useMemo(
    () =>
      ({
        chatId: data?.id,
        version: data?.embeddingsVersions
          ? Number.parseInt(
              data?.embeddingsVersions.split(', ')?.pop() ?? '0',
            ) + 1
          : 1,
      }) as Partial<GenerateEmbeddings>,
    [data?.embeddingsVersions, data?.id],
  ) as unknown as GenerateEmbeddings;

  const { schema } = useFieldsSchema([
    {
      name: 'version',
      label: t('glossary:labels.embeddingsVersion'),
      tooltip: t('glossary:tooltips.embeddingsVersion'),
      required: true,
      type: 'number',
    },
    {
      name: 'chunkSize',
      label: t('glossary:labels.chunkSize'),
      tooltip: t('glossary:tooltips.chunkSize'),
      required: true,
      type: 'number',
      defaultValue: 800,
    },
    {
      name: 'batchSize',
      label: t('glossary:labels.batchSize'),
      required: true,
      type: 'number',
      defaultValue: 5,
    },
    {
      name: 'chunkOverlap',
      label: t('glossary:labels.chunkOverlap'),
      tooltip: t('glossary:tooltips.chunkOverlap'),
      required: true,
      type: 'number',
      defaultValue: 120,
    },
    {
      name: 'transformer',
      label: t('glossary:labels.transformer'),
      type: 'select',
      options: {
        jamu: 'JAMU',
      },
    },
    {
      name: 'chunkTransformer',
      label: t('glossary:labels.chunkTransformer'),
      type: 'select',
      options: {
        contextualized: 'Contextualized',
      },
    },
    {
      $relevant: {
        $when: ({ formState }) =>
          (formState as TypedFormState<GenerateEmbeddings>).values
            .chunkTransformer === 'contextualized',
        $fields: [
          {
            name: 'chunkTransformerOptions.language',
            label: t('glossary:labels.language'),
            required: true,
            type: 'text',
            defaultValue: 'Czech',
          },
        ],
      },
    },
  ]);

  const handleSubmit = async (
    formState: TypedFormState<GenerateEmbeddings>,
  ) => {
    const { values } = formState;

    if (!values || !data?.id) {
      return;
    }

    try {
      await mutateAsync(
        generateEmbeddingsSchema.parse({
          ...values,
          chatId: data.id,
        }),
      );

      toast.success(t('chats:toasts.generateEmbeddings.success.title'), {
        description: t('chats:toasts.generateEmbeddings.success.description'),
      });

      setIsOpen(false);
    } catch (error) {
      console.error(error);
      toast.error(t('chats:toasts.generateEmbeddings.error.title'), {
        description: t('chats:toasts.generateEmbeddings.error.description'),
      });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Card title={t('chats:texts.embeddingsMeta')}>
      <DisplayValue label={t('glossary:labels.embeddingsCount')} Icon={Box}>
        {data?.embeddingsCount ?? 0}
      </DisplayValue>
      <DisplayValue
        label={t('glossary:labels.currentEmbeddingsCount')}
        Icon={ScrollText}
      >
        {data?.currentEmbeddingsCount ?? 0}
      </DisplayValue>
      <DisplayValue
        label={t('glossary:labels.embeddingsVersions')}
        Icon={FileStack}
      >
        {data?.embeddingsVersions ?? '-'}
      </DisplayValue>

      <Separator />

      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Trigger asChild>
          <Button variant='primary'>
            {t('glossary:actions.generateEmbeddings')}
          </Button>
        </Dialog.Trigger>
        <Dialog.Content>
          <Dialog.Title>
            {t('chats:dialogs.generateEmbeddings.title')}
          </Dialog.Title>
          <Dialog.Description>
            {t('chats:dialogs.generateEmbeddings.description')}
          </Dialog.Description>
          <BasicForm
            zodSchema={generateEmbeddingsSchema.omit({
              chatId: true,
            })}
            initialValues={initialValues}
            formApiRef={formApi}
            className='flex flex-col gap-4'
            onSubmit={handleSubmit}
          >
            <SchemaFields schema={schema} />
          </BasicForm>
          <Dialog.Footer>
            <Button
              type='button'
              disabled={isPending}
              loading={isPending}
              variant='secondary'
              onClick={event => {
                formApi.current?.submitForm();
              }}
            >
              {t('glossary:actions.generate')}
            </Button>
            <Dialog.Close asChild>
              <Button variant='ghost' outline>
                {t('glossary:actions.close')}
              </Button>
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </Card>
  );
});
