import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import zodCs from 'zod-i18n-map/locales/cs/zod.json';
import zodEn from 'zod-i18n-map/locales/en/zod.json';

import { AppSettings } from '@/constants/AppSettings';

import type auth from '../../public/locales/cs/auth.json';
import type chats from '../../public/locales/cs/chats.json';
import type chatUsers from '../../public/locales/cs/chatUsers.json';
import type enums from '../../public/locales/cs/enums.json';
import type errors from '../../public/locales/cs/errors.json';
import type faqs from '../../public/locales/cs/faqs.json';
import type globals from '../../public/locales/cs/globals.json';
import type glossary from '../../public/locales/cs/glossary.json';
import type organizations from '../../public/locales/cs/organizations.json';
import type sourceDocumentParts from '../../public/locales/cs/sourceDocumentParts.json';
import type sourceDocuments from '../../public/locales/cs/sourceDocuments.json';
import type threads from '../../public/locales/cs/threads.json';
import type users from '../../public/locales/cs/users.json';

export const DEFAULT_NAMESPACE = 'glossary';
export const SUPPORTED_LANGUAGES = ['cs', 'en'];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'cs',
    preload: ['cs'],
    ns: ['errors'], // Always preload errors namespace
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS: DEFAULT_NAMESPACE,
    supportedLngs: SUPPORTED_LANGUAGES,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${AppSettings.version}`,
    },
  });

i18n.addResourceBundle('cs', 'zod', zodCs);
i18n.addResourceBundle('en', 'zod', zodEn);

z.setErrorMap(zodI18nMap);

export { i18n };

// when new namespace is added, add it here
// we will be creating type just from cs language because that should be always full
export interface I18nCsResources {
  glossary: typeof glossary;
  auth: typeof auth;
  faqs: typeof faqs;
  organizations: typeof organizations;
  globals: typeof globals;
  errors: typeof errors;
  chats: typeof chats;
  chatUsers: typeof chatUsers;
  zod: typeof zodCs;
  users: typeof users;
  sourceDocuments: typeof sourceDocuments;
  sourceDocumentParts: typeof sourceDocumentParts;
  enums: typeof enums;
  threads: typeof threads;
}

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof DEFAULT_NAMESPACE;
    resources: I18nCsResources;
  }
}
