import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

export type ChatConfigFieldsProps = {};

export const ChatConfigFields = memo(
  function ChatConfigFields({}: ChatConfigFieldsProps) {
    const { t } = useTranslation(['glossary', 'enums']);
    const { schema } = useFieldsSchema([
      {
        name: 'chatbotConfig.systemName',
        label: t('glossary:labels.systemName'),
        required: true,
        defaultValue: 'Kanbu',
      },
      {
        name: 'chatbotConfig.systemAvatar',
        label: t('glossary:labels.systemAvatar'),
      },
      {
        name: 'chatbotConfig.initialMessage',
        label: t('glossary:labels.initialMessage'),
        type: 'text',
        uiControl: 'textarea',
        required: true,
        defaultValue:
          'Ahoj, jsem generativní AI chatbot Kanbu, který dokáže pomoci s technickou podporou pro zákazníky i znalostní bází pro zaměstnance. Umím pracovat s daty z webu, databáze i dokumentů a na jejich základě odpovídat okamžitě, správně a autenticky! Jak ti mohu pomoci?',
      },
      {
        name: 'chatbotConfig.userName',
        label: t('glossary:labels.userName'),
      },
      {
        name: 'chatbotConfig.bubbleTitle',
        label: t('glossary:labels.bubbleTitle'),
      },
      {
        name: 'chatbotConfig.bubbleDescription',
        label: t('glossary:labels.bubbleDescription'),
      },
      {
        name: 'chatbotConfig.maxMessages',
        label: t('glossary:labels.maxMessages'),
        type: 'number',
      },
      {
        name: 'chatbotConfig.maxCharacters',
        label: t('glossary:labels.maxCharacters'),
        type: 'number',
      },
    ]);

    return (
      <Card title={t('glossary:labels.chatbotConfig')}>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
