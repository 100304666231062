import type { SourceDocumentType } from '@kanbu/schema';
import { Role } from '@kanbu/schema/enums';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import {
  DataTable,
  type DataTableQuery,
} from '@/components/dataTable/DataTable';
import type { DataTableRowHandler } from '@/components/dataTable/dataTableTypes';
import { useSourceDocumentsCols } from '@/features/sourceDocuments/useSourceDocumentsCols';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chats/$chatId/documents/')({
  component: SourceDocumentsPage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId }),
});

export function SourceDocumentsPage() {
  const { chatId } = Route.useParams();
  const { t } = useTranslation(['globals', 'sourceDocuments', 'chats']);
  const columns = useSourceDocumentsCols();
  const navigate = useNavigate();

  const [data] = trpc.chats.findOne.useSuspenseQuery({ id: chatId });
  const breadCrumbItems = useBreadCrumb(
    [
      {
        label: t('globals:routeNames.chats'),
        to: '/chats',
      },
      {
        label: data.name,
        to: '/chats/$chatId/edit',
        params: { chatId },
      },
      {
        label: t('globals:routeNames.sourceDocuments'),
        to: '/chats/$chatId/documents',
        params: { chatId },
      },
    ],
    [t, data],
  );

  const handleQuery: DataTableQuery<SourceDocumentType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.sourceDocuments.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        where: {
          chat: chatId,
        },
        populate: ['partsCount'],
        orderBy: sort,
      });

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [],
  );

  const handleRow: DataTableRowHandler<SourceDocumentType> = useCallback(
    (event, row) => {
      navigate({
        to: '/chats/$chatId/documents/$sourceDocumentId/parts',
        params: {
          chatId,
          sourceDocumentId: row.original.id,
        },
      });
    },
    [chatId, navigate],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={t('sourceDocuments:texts.manage')}
      />
      <DataTable
        columns={columns}
        queryKey={[...getQueryKey(trpc.sourceDocuments.findAll), { chatId }]}
        onQueryFn={handleQuery}
        onRow={handleRow}
      />
    </>
  );
}
