import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_baseLayout/users/profile/change-password/',
)({
  component: ChangePasswordPage,
});

export function ChangePasswordPage() {
  return null;
}
