import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { FAQForm } from '@/features/faqs/FAQForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/faqs/$faqId/edit')({
  component: FAQsEditPage,
  loader: async ({ context: { trpcUtils }, params: { faqId } }) =>
    trpcUtils.faqs.findOne.ensureData({ id: faqId }),
});

export function FAQsEditPage() {
  const { faqId } = Route.useParams();
  const { t } = useTranslation(['glossary', 'faqs']);
  const [data] = trpc.faqs.findOne.useSuspenseQuery({ id: faqId });

  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('faqs:texts.manage'), to: '/faqs' },
      {
        label: data.title,
        to: '/faqs/$faqId/edit',
        params: { faqId },
      },
    ],
    [t, data],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={`${t('faqs:texts.edit')} - ${data.title}`}
      />
      <FAQForm data={data} />
    </>
  );
}
