import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { OrganizationForm } from '@/features/organizations/OrganizationForm';

export const Route = createFileRoute('/_baseLayout/organizations/create')({
  component: OrganizationsCreatePage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
});

export function OrganizationsCreatePage() {
  const { t } = useTranslation(['globals', 'organizations']);
  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('globals:routeNames.organizations'), to: '/organizations' },
      { label: t('organizations:texts.create'), to: '/organizations/create' },
    ],
    [t],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={t('organizations:texts.create')}
      />
      <OrganizationForm />
    </>
  );
}
