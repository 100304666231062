import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { UserForm } from '@/features/users/components/UserForm';

export const Route = createFileRoute('/_baseLayout/users/create')({
  component: UsersCreatePage,
});

export function UsersCreatePage() {
  const { t } = useTranslation(['glossary', 'users']);
  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('users:texts.manage'), to: '/users' },
      { label: t('users:texts.create'), to: '/users/create' },
    ],
    [t],
  );

  return (
    <>
      <BreadCrumb items={breadCrumbItems} title={t('users:texts.create')} />
      <UserForm />
    </>
  );
}
