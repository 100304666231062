import type { UserType } from '@kanbu/schema';
import { createUserSchema, updateUserSchema } from '@kanbu/schema/contracts';
import type { TypedFormState } from '@utima/ui-informed';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { CRUDForm } from '@/components/form/CRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

import { PasswordFields } from '../../../components/fields/passwordFields/PasswordFields';
import { useUserMutation } from '../useUserMutation';

export type ProfileFormProps = {
  data: UserType;
};

type ProfileFormValues = UserType & {
  confirmPassword: string;
  oldPassword: string;
};

export const ProfileForm = memo(function ProfileForm({
  data,
}: ProfileFormProps) {
  const { t } = useTranslation(['glossary', 'enums']);
  const {
    update: { mutateAsync: update },
  } = useUserMutation();

  const { schema: detailsSchema } = useFieldsSchema([
    {
      name: 'firstName',
      label: t('glossary:labels.firstName'),
      required: true,
    },
    {
      name: 'lastName',
      label: t('glossary:labels.lastName'),
      required: true,
    },
    {
      name: 'email',
      label: t('glossary:labels.email'),
      required: true,
      disabled: true,
    },
  ]);

  const handleSubmit = useCallback(
    async (formState: TypedFormState<ProfileFormValues>) => {
      const { values } = formState;

      if (!values) {
        return;
      }

      const { confirmPassword, email, ...restValues } = values;

      await update(
        updateUserSchema.parse({
          ...restValues,
          id: data.id,
        }),
      );
    },
    [data, update],
  );

  return (
    <CRUDForm
      initialValues={data as ProfileFormValues}
      zodSchema={
        data
          ? createUserSchema
          : updateUserSchema.extend({
              email: z.string().email().max(255),
            })
      }
      onSubmit={handleSubmit}
      showDelete={false}
    >
      <CRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </CRUDForm.Col>
      <CRUDForm.Col>
        <PasswordFields />
      </CRUDForm.Col>
      <CRUDForm.Col>
        <MetaFields data={data} />
      </CRUDForm.Col>
    </CRUDForm>
  );
});
