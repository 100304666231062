import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';
import { ChatModel } from '../enums';

export const chatThemeSchema = z
  .object({
    primary: z.string().nullish(),
    primaryFg: z.string().nullish(),
    secondary: z.string().nullish(),
    secondaryFg: z.string().nullish(),
    backgroundSecondary: z.string().nullish(),
    background: z.string().nullish(),
    foreground: z.string().nullish(),
    placeholder: z.string().nullish(),
    logo: z.string().nullish(),
    logoBubble: z.string().nullish(),
  })
  .strict();

export type ChatThemeConfig = z.infer<typeof chatThemeSchema>;

export const agentConfigSchema = z
  .object({
    systemPrompt: z.string(),
    tone: z.string(),
    language: z.string(),
    rewriteRetries: z.number().int().nonnegative().optional(),
    summaryThreshold: z.number().int().nonnegative().optional(),
    retrievalCount: z.number().int().nonnegative().optional(),
    rerankCount: z.number().int().nonnegative().optional(),
    minScore: z.number().optional(),
  })
  .strict();

export type AgentConfig = z.infer<typeof agentConfigSchema>;

export const chatbotConfigSchema = z
  .object({
    systemName: z.string(),
    systemAvatar: z.string().optional(),
    userName: z.string().optional(),
    maxMessages: z.number().int().nonnegative().optional(),
    maxCharacters: z.number().int().nonnegative().optional(),
    initialMessage: z.string().optional(),
    bubbleTitle: z.string().optional(),
    bubbleDescription: z.string().optional(),
  })
  .strict();

export type ChatbotConfig = z.infer<typeof chatbotConfigSchema>;

export const chatSchema = baseOrganizationEntitySchema.extend({
  name: z.string(),
  agent: z.string(),
  themeConfig: chatThemeSchema.optional(),
  chatbotConfig: chatbotConfigSchema,
  agentConfig: agentConfigSchema,
  model: z.nativeEnum(ChatModel),
  embeddingsVersion: z.number().int().nonnegative(),
});

export const createChatSchema = chatSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    organization: true,
  })
  .strict();

export const updateChatSchema = chatSchema
  .partial()
  .required({
    id: true,
  })
  .omit({
    organization: true,
  })
  .strict();

export type ChatDTO = z.infer<typeof chatSchema>;
export type CreateChatDTO = z.infer<typeof createChatSchema>;
export type UpdateChatDTO = z.infer<typeof updateChatSchema>;

export const streamInitChunkSchema = z.object({
  type: z.literal('init'),
  data: z.object({
    messageId: z.string(),
  }),
});

export const streamTextChunkSchema = z.object({
  type: z.literal('text'),
  data: z.object({
    content: z.string(),
  }),
  metadata: z
    .object({
      node: z.string().optional(),
    })
    .optional(),
});

export const streamChunkSchema = z.union([
  streamInitChunkSchema,
  streamTextChunkSchema,
]);

export type StreamInitChunk = z.infer<typeof streamInitChunkSchema>;
export type StreamTextChunk = z.infer<typeof streamTextChunkSchema>;
export type StreamChunk = z.infer<typeof streamChunkSchema>;
