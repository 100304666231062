import type { SourceDocumentPartType } from '@kanbu/schema';
import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import {
  DataTable,
  type DataTableQuery,
} from '@/components/dataTable/DataTable';
import { useSourceDocumentPartsCols } from '@/features/sourceDocuments/useSourceDocumentPartsCols';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute(
  '/_baseLayout/chats/$chatId/documents/$sourceDocumentId/parts/',
)({
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
  component: SourceDocumentPartsPage,
});

export function SourceDocumentPartsPage() {
  const { chatId, sourceDocumentId } = Route.useParams();
  const { t } = useTranslation(['globals', 'sourceDocumentParts', 'chats']);
  const columns = useSourceDocumentPartsCols();
  const breadCrumbItems = useBreadCrumb(
    [
      {
        type: 'more',
      },
      {
        label: t('globals:routeNames.sourceDocuments'),
        to: '/chats/$chatId/documents',
        params: { chatId },
      },
      {
        label: t('globals:routeNames.sourceDocumentParts'),
        to: '/chats/$chatId/documents/$sourceDocumentId/parts',
        params: { chatId },
      },
    ],
    [t, chatId],
  );

  const handleQuery: DataTableQuery<SourceDocumentPartType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.sourceDocumentParts.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        where: {
          parent: sourceDocumentId,
        },
        orderBy: sort,
      });

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [sourceDocumentId],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={t('sourceDocumentParts:texts.manage')}
      />
      <DataTable
        columns={columns}
        queryKey={[
          ...getQueryKey(trpc.sourceDocumentParts.findAll),
          { sourceDocumentId, chatId },
        ]}
        onQueryFn={handleQuery}
      />
    </>
  );
}
