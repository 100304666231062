import type { ThreadType } from '@kanbu/schema';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { Link } from '@tanstack/react-router';
import { createColumnHelper, type ColumnDef } from '@tanstack/react-table';
import { Button } from '@utima/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsCell } from '@/components/dataTable/cells/ActionsCell';

import { useThreadMutation } from './useThreadMutation';

const columnHelper = createColumnHelper<ThreadType>();

export type UseThreadsColsProps = {
  showUserColumn?: boolean;
};

export function useThreadsCols({
  showUserColumn = false,
}: UseThreadsColsProps = {}) {
  const { t } = useTranslation(['glossary', 'threads']);
  const {
    remove: { mutateAsync: remove },
  } = useThreadMutation();

  return useMemo(
    () =>
      [
        columnHelper.accessor(row => row.title, {
          id: 'title',
          header: t('glossary:labels.title'),
          cell: row => formatUtils.truncate(row.getValue(), 80),
        }),
        showUserColumn &&
          columnHelper.accessor(row => row.chatUser.id, {
            id: 'chatUser',
            header: t('glossary:labels.user'),
            cell: row => (
              <Button data-table='prevent' asChild variant='link' size='xs'>
                <Link
                  className='text-primary'
                  to='/chat-users/$chatUserId/threads'
                  params={{ chatUserId: row.getValue() }}
                >
                  {row.getValue()}
                </Link>
              </Button>
            ),
            meta: {
              sortable: 'chatUser',
            },
          }),
        columnHelper.accessor(row => row.createdAt, {
          id: 'createdAt',
          header: t('glossary:labels.createdAt'),
          cell: row => formatUtils.date(row.getValue(), DateFormat.DateTime),
          meta: {
            sortable: 'createdAt',
          },
        }),
        columnHelper.accessor(row => row.chatMessageCount, {
          id: 'chatMessageCount',
          header: t('glossary:labels.chatMessageCount'),
          meta: {
            sortable: 'chatMessageCount',
            align: 'center',
          },
        }),
        columnHelper.accessor(row => row.chatMessageCount, {
          id: 'feedbackCount',
          header: t('glossary:labels.feedbackCount'),
          meta: {
            sortable: 'feedbackCount',
            align: 'center',
          },
        }),
        columnHelper.display({
          id: 'actions',
          header: t('glossary:labels.actions'),
          cell: ({ row }) => (
            <ActionsCell onDelete={() => remove({ id: row.original.id })} />
          ),
          meta: {
            align: 'center',
          },
        }),
      ].filter(Boolean) as ColumnDef<ThreadType, any>[],
    [t, remove, showUserColumn],
  );
}
