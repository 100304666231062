import type { ChatMessageType } from '@kanbu/schema';
import { ChatRole } from '@kanbu/schema/enums';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { cn } from '@utima/ui';
import { memo } from 'react';

export type MessageProps = {
  message: ChatMessageType;
};

export const Message = memo(function Message({ message }: MessageProps) {
  const { role, text, createdAt } = message;

  return (
    <div className={cn('flex flex-col gap-1')}>
      <div
        className={cn(
          'flex max-w-[90%] rounded-2xl px-3.5 py-3 text-darkGray',
          {
            ['rounded-br-none ml-auto bg-secondary']: role === ChatRole.User,
            ['rounded-bl-none bg-gray-200']: role === ChatRole.Assistant,
          },
        )}
      >
        <div
          className='markdown-wrapper -mb-4 text-sm'
          dangerouslySetInnerHTML={{ __html: text ?? '' }}
        />
      </div>
      <div
        className={cn('text-xs mt-1', {
          ['text-right']: role === ChatRole.User,
          ['text-left']: role === ChatRole.Assistant,
        })}
      >
        {formatUtils.date(createdAt, DateFormat.Date)}
      </div>
    </div>
  );
});
