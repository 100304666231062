import { Role } from '@kanbu/schema/enums';
import { formatUtils } from '@kanbu/shared';
import { Link, useNavigate } from '@tanstack/react-router';
import { Avatar, Dropdown, toast } from '@utima/ui';
import { Settings, SquareArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import logo from '@/assets/images/logo-black.svg';
import { OrganizationSwitch } from '@/features/organizations/OrganizationSwitch';
import { trpc } from '@/services/trpc';

import { useBoundStore } from '../../store/store';
import { Protected } from '../protected/Protected';

function TopBar() {
  const navigate = useNavigate();
  const { t } = useTranslation(['glossary', 'auth']);

  const logout = useBoundStore(state => state.logout);
  const user = useBoundStore(state => state.user);
  const logoutMutation = trpc.auth.logout.useMutation({
    onError: error => {
      toast.error(t('auth:toasts.logout.error.title'), {
        description: t('auth:toasts.logout.error.description'),
      });
    },
    onSettled: () => {
      logout();
      navigate({ to: '/login' });
    },
  });

  return (
    <div className='flex w-full shrink-0 items-center justify-between py-3'>
      <Link to='/'>
        <img src={logo} alt='Kanbu.ai' className='h-6 w-auto' />
      </Link>
      <div className='flex items-center gap-9'>
        <Protected roles={[Role.SuperAdmin]}>
          <OrganizationSwitch />
        </Protected>
        <Dropdown.Root>
          <Dropdown.Trigger>
            <div className='group flex items-center gap-3'>
              <span className='transition-colors group-hover:text-primary'>
                {formatUtils.fullName(user)}
              </span>
              <Avatar
                className='cursor-pointer'
                src={user?.photo}
                fallback={formatUtils.initials(formatUtils.fullName(user))}
              />
            </div>
          </Dropdown.Trigger>
          <Dropdown.Content side='bottom' align='end'>
            <Dropdown.Item className='flex items-center gap-2' asChild>
              <Link to='/users/profile'>
                <Settings className='size-5' />
                {t('glossary:labels.profile')}
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              className='flex items-center gap-2'
              onClick={() => logoutMutation.mutate()}
            >
              <SquareArrowLeft className='size-5' />
              {t('glossary:actions.logout')}
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Root>
      </div>
    </div>
  );
}

export default TopBar;
