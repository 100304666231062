import type { OrganizationType } from '@kanbu/schema';
import {
  createOrganizationSchema,
  updateOrganizationSchema,
} from '@kanbu/schema/contracts';
import { useNavigate, Navigate } from '@tanstack/react-router';
import type { TypedFormState } from '@utima/ui-informed';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { CRUDForm } from '@/components/form/CRUDForm';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

import { useOrganizationMutation } from './useOrganizationMutation';

export type OrganizationFormProps = {
  data?: OrganizationType;
};

export const OrganizationForm = memo(function OrganizationForm({
  data,
}: OrganizationFormProps) {
  const { t } = useTranslation(['glossary']);
  const navigate = useNavigate();
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove, isSuccess: isRemoved },
    update: { mutateAsync: update },
  } = useOrganizationMutation();

  const { schema: detailsSchema } = useFieldsSchema([
    {
      name: 'name',
      label: t('glossary:labels.name'),
      required: true,
    },
  ]);

  const handleDelete = useCallback(async () => {
    if (data) {
      await remove({ id: data.id });
    }
  }, [data, remove]);

  const handleSubmit = useCallback(
    async (formState: TypedFormState<OrganizationType | undefined>) => {
      const { values } = formState;

      if (!values) {
        return;
      }

      if (data) {
        await update(
          updateOrganizationSchema.parse({
            ...values,
            id: data.id,
          }),
        );
      } else {
        const data = await create(createOrganizationSchema.parse(values));

        // Redirect to edit page on success
        if (data) {
          navigate({
            to: '/organizations/$organizationId/edit',
            params: { organizationId: data.id },
          });
        }
      }
    },
    [create, data, navigate, update],
  );

  // Redirect on removal
  if (isRemoved) {
    return <Navigate to='/organizations' />;
  }

  return (
    <CRUDForm
      initialValues={data}
      zodSchema={data ? createOrganizationSchema : updateOrganizationSchema}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    >
      <CRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </CRUDForm.Col>
      <CRUDForm.Col />
      <CRUDForm.Col>
        <MetaFields data={data} />
      </CRUDForm.Col>
    </CRUDForm>
  );
});
