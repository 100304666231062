import type { OrganizationType } from '@kanbu/schema';
import { Role } from '@kanbu/schema/enums';
import { createSearchExpression } from '@kanbu/schema/shared';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewButton } from '@/components/actionButtons/AddNewButton';
import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import {
  DataTable,
  type DataTableQuery,
} from '@/components/dataTable/DataTable';
import type { DataTableRowHandler } from '@/components/dataTable/dataTableTypes';
import { useOrganizationsCols } from '@/features/organizations/useOrganizationsCols';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/organizations/')({
  component: OrganizationsPage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
});

export function OrganizationsPage() {
  const { t } = useTranslation(['globals', 'organizations']);
  const columns = useOrganizationsCols();
  const navigate = useNavigate();
  const breadCrumbItems = useBreadCrumb(
    [{ label: t('globals:routeNames.organizations'), to: '/organizations' }],
    [t],
  );

  const handleQuery: DataTableQuery<OrganizationType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.organizations.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        where: {
          searchable: { $like: createSearchExpression(search) },
        },
        orderBy: sort,
      });

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [],
  );

  const handleRow: DataTableRowHandler<OrganizationType> = useCallback(
    (event, row) => {
      navigate({
        to: '/organizations/$organizationId/edit',
        params: {
          organizationId: row.original.id,
        },
      });
    },
    [navigate],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={t('organizations:texts.manage')}
      >
        <AddNewButton to='/organizations/create'>
          {t('organizations:actions.create')}
        </AddNewButton>
      </BreadCrumb>
      <DataTable
        columns={columns}
        queryKey={getQueryKey(trpc.organizations.findAll)}
        onQueryFn={handleQuery}
        onRow={handleRow}
        showSearch
      />
    </>
  );
}
