import { formatUtils } from '@kanbu/shared';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { ProfileForm } from '@/features/users/components/ProfileForm';
import { trpc } from '@/services/trpc';
import { useBoundStore } from '@/store/store';

export const Route = createFileRoute('/_baseLayout/users/profile/')({
  component: ProfilePage,
  loader: async ({ context: { trpcUtils } }) =>
    trpcUtils.users.findOne.ensureData({
      id: useBoundStore.getState().user!.id,
    }),
});

export function ProfilePage() {
  const { t } = useTranslation(['glossary', 'users']);
  const user = useBoundStore(state => state.user);
  const [data] = trpc.users.findOne.useSuspenseQuery({ id: user!.id });

  const breadCrumbItems = useBreadCrumb(
    [
      {
        label: t('glossary:labels.profile'),
        to: '/users/profile',
      },
    ],
    [t, data],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={`${t('users:texts.profile')} - ${formatUtils.fullName(data)}`}
      />
      <ProfileForm data={data} />
    </>
  );
}
