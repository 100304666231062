import type { ChatType } from '@kanbu/schema';
import { createChatSchema, updateChatSchema } from '@kanbu/schema/contracts';
import { Agent, ChatModel } from '@kanbu/schema/enums';
import { useNavigate, Navigate } from '@tanstack/react-router';
import type { TypedFormState } from '@utima/ui-informed';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { CRUDForm } from '@/components/form/CRUDForm';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';

import { AgentConfigFields } from './formFields/AgentConfigFields';
import { ChatConfigFields } from './formFields/ChatConfigFields';
import { ChatThemeFields } from './formFields/ChatThemeFields';
import { EmbeddingsMetaFields } from './formFields/EmbeddingsMetaFields';
import { SourceDocumentsMetaFields } from './formFields/SourceDocumentsMetaFields';
import { useChatMutation } from '../useChatMutation';

export type ChatFormProps = {
  data?: ChatType;
};

export const ChatForm = memo(function ChatForm({ data }: ChatFormProps) {
  const { t } = useTranslation(['glossary', 'enums']);
  const navigate = useNavigate();
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove, isSuccess: isRemoved },
    update: { mutateAsync: update },
  } = useChatMutation();

  const { schema: detailsSchema } = useFieldsSchema([
    {
      name: 'name',
      label: t('glossary:labels.name'),
      required: true,
    },
    {
      name: 'agent',
      label: t('glossary:labels.agent'),
      type: 'select',
      options: optionsFromEnum(Agent, key => t(`enums:agent.${key}`)),
      required: true,
      defaultValue: Agent.Rag,
    },
    {
      name: 'model',
      label: t('glossary:labels.model'),
      type: 'select',
      options: optionsFromEnum(ChatModel, key => t(`enums:chatModel.${key}`)),
      required: true,
      defaultValue: ChatModel.Gpt4o_2024_08_06,
    },
    {
      name: 'embeddingsVersion',
      label: t('glossary:labels.embeddingsVersion'),
      required: true,
      type: 'number',
      defaultValue: 1,
    },
  ]);

  const handleDelete = useCallback(async () => {
    if (data) {
      await remove({ id: data.id });
    }
  }, [data, remove]);

  const handleSubmit = useCallback(
    async (formState: TypedFormState<ChatType | undefined>) => {
      const { values } = formState;

      if (!values) {
        return;
      }

      if (data) {
        await update(
          updateChatSchema.parse({
            ...values,
            id: data.id,
          }),
        );
      } else {
        const data = await create(createChatSchema.parse(values));

        // Redirect to edit page on success
        if (data) {
          navigate({
            to: '/chats/$chatId/edit',
            params: { chatId: data.id },
          });
        }
      }
    },
    [create, data, navigate, update],
  );

  // Redirect on removal
  if (isRemoved) {
    return <Navigate to='/chats' />;
  }

  return (
    <CRUDForm
      initialValues={data}
      zodSchema={data ? createChatSchema : updateChatSchema}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    >
      <CRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
        <AgentConfigFields />
      </CRUDForm.Col>
      <CRUDForm.Col>
        <ChatConfigFields />
        <ChatThemeFields />
      </CRUDForm.Col>
      <CRUDForm.Col>
        <MetaFields data={data} />
        <EmbeddingsMetaFields data={data} />
        <SourceDocumentsMetaFields data={data} />
      </CRUDForm.Col>
    </CRUDForm>
  );
});
