import type { ThreadType } from '@kanbu/schema';
import { createSearchExpression } from '@kanbu/schema/shared';
import { createFileRoute } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { ChatDrawer } from '@/components/chat/ChatDrawer';
import {
  type DataTableQuery,
  type DataTableRowHandler,
  DataTable,
} from '@/components/dataTable/DataTable';
import { useThreadsCols } from '@/features/threads/useThreadsCols';
import { trpcClient, trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/threads/')({
  component: ThreadsPage,
});

export function ThreadsPage() {
  const { t } = useTranslation(['threads', 'globals']);
  const columns = useThreadsCols({ showUserColumn: true });
  const breadCrumbItems = useBreadCrumb(
    [{ label: t('globals:routeNames.threads'), to: '/threads' }],
    [t],
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [thread, setThread] = useState<ThreadType | null>(null);

  const handleQuery: DataTableQuery<ThreadType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.threads.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        where: {
          searchable: { $like: createSearchExpression(search) },
        },
        populate: ['chatMessageCount', 'messages', 'chatUser'],
        orderBy: sort,
      });

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [],
  );

  const handleRow: DataTableRowHandler<ThreadType> = useCallback(
    (event, row) => {
      setIsDrawerOpen(true);
      setThread(row.original);
    },
    [],
  );

  return (
    <>
      <BreadCrumb items={breadCrumbItems} title={t('threads:texts.manage')} />
      <DataTable
        columns={columns}
        onRow={handleRow}
        queryKey={getQueryKey(trpc.threads.findAll)}
        onQueryFn={handleQuery}
        showSearch
      />
      <ChatDrawer
        open={isDrawerOpen}
        onOpenChange={setIsDrawerOpen}
        thread={thread}
      />
    </>
  );
}
