import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { ChatForm } from '@/features/chats/components/ChatForm';

export const Route = createFileRoute('/_baseLayout/chats/create')({
  component: ChatsCreatePage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
});

export function ChatsCreatePage() {
  const { t } = useTranslation(['globals', 'chats']);
  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('globals:routeNames.chats'), to: '/chats' },
      { label: t('chats:texts.create'), to: '/chats/create' },
    ],
    [t],
  );

  return (
    <>
      <BreadCrumb items={breadCrumbItems} title={t('chats:texts.create')} />
      <ChatForm />
    </>
  );
}
