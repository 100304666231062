import { Role } from '@kanbu/schema/enums';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { ChatForm } from '@/features/chats/components/ChatForm';
import { trpc } from '@/services/trpc';

const requestParams = {
  populate: [
    'embeddingsCount',
    'embeddingsVersions',
    'currentEmbeddingsCount',
    'documentsCount',
    'documentPartsCount',
  ],
};

export const Route = createFileRoute('/_baseLayout/chats/$chatId/edit')({
  component: ChatsEditPage,
  beforeLoad: async ({ context: { accessGuard } }) =>
    accessGuard([Role.SuperAdmin]),
  loader: async ({ context: { trpcUtils }, params: { chatId } }) =>
    trpcUtils.chats.findOne.ensureData({ id: chatId, ...requestParams }),
});

export function ChatsEditPage() {
  const { chatId } = Route.useParams();
  const { t } = useTranslation(['globals', 'chats']);
  const [data] = trpc.chats.findOne.useSuspenseQuery({
    id: chatId,
    ...requestParams,
  });

  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('globals:routeNames.chats'), to: '/chats' },
      {
        label: data.name,
        to: '/chats/$chatId/edit',
        params: { chatId },
      },
    ],
    [t, data],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={`${t('chats:texts.edit')} - ${data.name}`}
      />
      <ChatForm data={data} />
    </>
  );
}
