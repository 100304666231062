import type { ChatUserType } from '@kanbu/schema';
import { createSearchExpression } from '@kanbu/schema/shared';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import {
  DataTable,
  type DataTableQuery,
  type DataTableRowHandler,
} from '@/components/dataTable/DataTable';
import { useChatUsersCols } from '@/features/chatUsers/useChatUsersCols';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/chat-users/')({
  component: ChatUsersPage,
});

export function ChatUsersPage() {
  const { t } = useTranslation(['glossary', 'globals', 'chatUsers']);
  const columns = useChatUsersCols();
  const navigate = useNavigate();
  const breadCrumbItems = useBreadCrumb(
    [{ label: t('globals:routeNames.chatUsers'), to: '/chat-users' }],
    [t],
  );

  const handleQuery: DataTableQuery<ChatUserType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.chatUsers.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        where: {
          searchable: { $like: createSearchExpression(search) },
        },
        populate: ['threadsCount'],
        orderBy: sort,
      });

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [],
  );

  const handleRow: DataTableRowHandler<ChatUserType> = useCallback(
    (event, row) => {
      navigate({
        to: '/chat-users/$chatUserId/threads',
        params: {
          chatUserId: row.original.id,
        },
      });
    },
    [navigate],
  );

  return (
    <>
      <BreadCrumb items={breadCrumbItems} title={t('chatUsers:texts.manage')} />
      <DataTable
        columns={columns}
        queryKey={getQueryKey(trpc.chatUsers.findAll)}
        onQueryFn={handleQuery}
        onRow={handleRow}
        showSearch
      />
    </>
  );
}
