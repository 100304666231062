import type { ThreadType } from '@kanbu/schema';
import { memo } from 'react';
import { Drawer, type DialogProps } from 'vaul';

import { Chat } from './Chat';

export type ChatDrawerProps = {
  thread: ThreadType | undefined | null;
} & DialogProps;

export const ChatDrawer = memo(function ChatDrawer({
  thread,
  ...restProps
}: ChatDrawerProps) {
  return (
    <Drawer.Root direction='right' {...restProps}>
      <Drawer.Portal>
        <Drawer.Overlay className='fixed inset-0 z-[100] bg-black/40' />
        <Drawer.Content className='fixed inset-y-0 right-0 z-[110] flex outline-none'>
          <div className='my-2 mr-2 flex w-[460px] max-w-full grow flex-col overflow-auto rounded-[16px] bg-zinc-50 p-5'>
            <div className='mb-4 border-b pb-2'>
              <Drawer.Title className='text-base font-bold'>
                {thread?.title}
              </Drawer.Title>
              <Drawer.Description className='text-xs text-muted-fg'>
                {thread?.id}
              </Drawer.Description>
            </div>
            <Chat thread={thread} />
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
});
