import type { UserType } from '@kanbu/schema';
import { createSearchExpression } from '@kanbu/schema/shared';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { getQueryKey } from '@trpc/react-query';
import { UserXIcon } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewButton } from '@/components/actionButtons/AddNewButton';
import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import {
  DataTable,
  type DataTableQuery,
} from '@/components/dataTable/DataTable';
import type { DataTableRowHandler } from '@/components/dataTable/dataTableTypes';
import { useUsersCols } from '@/features/users/useUsersCols';
import { trpc, trpcClient } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/users/')({
  component: UsersPage,
});

export function UsersPage() {
  const { t } = useTranslation(['globals', 'users']);
  const [limitReached, setLimitReached] = useState(false);
  const columns = useUsersCols();
  const navigate = useNavigate();
  const breadCrumbItems = useBreadCrumb(
    [{ label: t('globals:routeNames.users'), to: '/users' }],
    [t],
  );

  const handleQuery: DataTableQuery<UserType> = useCallback(
    async ({ page, pageSize, search, sort }) => {
      const data = await trpcClient.users.findAll.query({
        pagination: {
          page: page,
          pageSize: pageSize,
        },
        where: {
          searchable: { $like: createSearchExpression(search) },
        },
        orderBy: sort,
      });

      setLimitReached(data.items.length >= 3);

      return {
        items: data.items,
        pageCount: data.meta.pageCount,
      };
    },
    [],
  );

  const handleRow: DataTableRowHandler<UserType> = useCallback(
    (event, row) => {
      navigate({
        to: '/users/$userId/edit',
        params: {
          userId: row.original.id,
        },
      });
    },
    [navigate],
  );

  return (
    <>
      <BreadCrumb items={breadCrumbItems} title={t('users:texts.manage')}>
        {limitReached && (
          <p className='flex items-center gap-2 font-medium text-red-500'>
            <UserXIcon /> {t('users:texts.userLimitReached')}
          </p>
        )}
        <AddNewButton disabled={limitReached} to='/users/create'>
          {t('users:actions.create')}
        </AddNewButton>
      </BreadCrumb>
      <DataTable
        columns={columns}
        queryKey={getQueryKey(trpc.users.findAll)}
        onQueryFn={handleQuery}
        onRow={handleRow}
        showSearch
      />
    </>
  );
}
