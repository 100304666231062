import { formatUtils } from '@kanbu/shared';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { BreadCrumb } from '@/components/breadCrumb/BreadCrumb';
import { useBreadCrumb } from '@/components/breadCrumb/useBreadCrumb';
import { UserForm } from '@/features/users/components/UserForm';
import { trpc } from '@/services/trpc';

export const Route = createFileRoute('/_baseLayout/users/$userId/edit')({
  component: UsersEditPage,
  loader: async ({ context: { trpcUtils }, params: { userId } }) =>
    trpcUtils.users.findOne.ensureData({ id: userId }),
});

export function UsersEditPage() {
  const { userId } = Route.useParams();
  const { t } = useTranslation(['globals', 'users']);
  const [data] = trpc.users.findOne.useSuspenseQuery({ id: userId });

  const breadCrumbItems = useBreadCrumb(
    [
      { label: t('globals:routeNames.users'), to: '/users' },
      {
        label: formatUtils.fullName(data),
        to: '/users/$userId/edit',
        params: { userId },
      },
    ],
    [t, data],
  );

  return (
    <>
      <BreadCrumb
        items={breadCrumbItems}
        title={`${t('users:texts.edit')} - ${formatUtils.fullName(data)}`}
      />
      <UserForm data={data} />
    </>
  );
}
