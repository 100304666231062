import type { UserType } from '@kanbu/schema';
import { createUserSchema, updateUserSchema } from '@kanbu/schema/contracts';
import { Role, RoleWOSuperAdmin } from '@kanbu/schema/enums';
import { Navigate, useNavigate } from '@tanstack/react-router';
import type { TypedFormState } from '@utima/ui-informed';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Card } from '@/components/card/Card';
import { MetaFields } from '@/components/fields/MetaFields';
import { CRUDForm } from '@/components/form/CRUDForm';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useBoundStore } from '@/store/store';

import { useUserMutation } from '../useUserMutation';

export type UserFormProps = {
  data?: UserType;
};

export const UserForm = memo(function UserForm({ data }: UserFormProps) {
  const { t } = useTranslation(['glossary', 'enums']);
  const navigate = useNavigate();
  const user = useBoundStore(state => state.user);
  const {
    create: { mutateAsync: create },
    remove: { mutateAsync: remove, isSuccess: isRemoved },
    update: { mutateAsync: update },
  } = useUserMutation();

  const { schema: detailsSchema } = useFieldsSchema([
    {
      name: 'firstName',
      label: t('glossary:labels.firstName'),
      required: true,
    },
    {
      name: 'lastName',
      label: t('glossary:labels.lastName'),
      required: true,
    },
    {
      name: 'email',
      label: t('glossary:labels.email'),
      required: true,
      disabled: !!data,
    },
    {
      name: 'role',
      label: t('glossary:labels.role'),
      type: 'select',
      options: optionsFromEnum(
        user?.role === Role.SuperAdmin ? Role : RoleWOSuperAdmin,
        key => t(`enums:role.${key}`),
      ),
      required: true,
    },
  ]);

  const handleDelete = useCallback(async () => {
    if (data) {
      await remove({ id: data.id });
    }
  }, [data, remove]);

  const handleSubmit = useCallback(
    async (formState: TypedFormState<UserType | undefined>) => {
      const { values } = formState;

      if (!values) {
        return;
      }

      if (data) {
        const { email, ...restValues } = values;

        await update(
          updateUserSchema.parse({
            ...restValues,
            id: data.id,
          }),
        );
      } else {
        const data = await create(createUserSchema.parse(values));

        // Redirect to edit page on success
        if (data) {
          navigate({
            to: '/users/$userId/edit',
            params: { userId: data.id },
          });
        }
      }
    },
    [create, data, navigate, update],
  );

  // Redirect on removal
  if (isRemoved) {
    return <Navigate to='/users' />;
  }

  return (
    <CRUDForm
      initialValues={data}
      zodSchema={
        data
          ? createUserSchema
          : updateUserSchema.extend({
              email: z.string().email().max(255),
            })
      }
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    >
      <CRUDForm.Col>
        <Card title={t('glossary:labels.basicDetails')}>
          <SchemaFields schema={detailsSchema} />
        </Card>
      </CRUDForm.Col>
      <CRUDForm.Col />
      <CRUDForm.Col>
        <MetaFields data={data} />
      </CRUDForm.Col>
    </CRUDForm>
  );
});
