import { Button, cn, Dropdown } from '@utima/ui';
import { cva, type VariantProps } from 'class-variance-authority';
import { MoreHorizontal } from 'lucide-react';
import { memo, type ComponentPropsWithoutRef, type ReactNode } from 'react';

import { Loader } from '../loader/Loader';

const cardStyles = cva('flex flex-col gap-4 rounded-lg p-4', {
  variants: {
    size: {
      sm: 'gap-3 p-3',
      md: 'gap-4 p-4',
      lg: 'gap-4 p-4',
    },
    variant: {
      plain: 'border-none p-0',
      default: 'border border-border bg-white',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'default',
  },
});

export type CardProps = {
  title?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  loading?: boolean;
} & VariantProps<typeof cardStyles> &
  Omit<ComponentPropsWithoutRef<'div'>, 'title'>;

export const Card = memo(function Card({
  title,
  children,
  className,
  variant,
  actions = [],
  loading = false,
  size,
  ...restProps
}: CardProps) {
  return (
    <div
      className={cn(cardStyles({ size, variant }), className)}
      {...restProps}
    >
      {title && (
        <div
          className={cn(
            'font-bold text-dark-blue flex items-center justify-between',
            variant !== 'plain' && 'border-b border-light-blue2 pb-2',
            {
              ['text-sm']: size === 'md',
              ['text-base']: size === 'md',
              ['text-lg']: size === 'lg',
            },
          )}
        >
          {title}
          {Array.isArray(actions) && actions?.length ? (
            <Dropdown.Root modal={false}>
              <Dropdown.Trigger asChild>
                <Button
                  variant='ghost'
                  size='icon-xs'
                  icon={<MoreHorizontal size={18} />}
                />
              </Dropdown.Trigger>
              <Dropdown.Content side='bottom' align='end'>
                {actions}
              </Dropdown.Content>
            </Dropdown.Root>
          ) : null}
        </div>
      )}
      <div className={cn('flex flex-col gap-3 relative')}>
        <Loader visible={loading} />
        {children}
      </div>
    </div>
  );
});
