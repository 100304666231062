import type { ChatMessageType, ThreadType } from '@kanbu/schema';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { memo, useEffect, useState } from 'react';

import { Message } from './Message';
import { Loader } from '../loader/Loader';

export type ChatProps = {
  thread: ThreadType | undefined | null;
};

export const Chat = memo(function Chat({ thread }: ChatProps) {
  const [parsedThread, setParsedThread] = useState<ThreadType | null>(null);

  useEffect(() => {
    async function sanitizeMessages() {
      if (!thread?.messages?.length) {
        return;
      }

      const sanitizedMessages = await Promise.all(
        thread?.messages.map(async messageItem => {
          const sanitized = await marked(DOMPurify.sanitize(messageItem.text));

          return {
            ...messageItem,
            text: sanitized,
          } as ChatMessageType;
        }),
      );

      const markedThread: ThreadType = {
        ...thread,
        messages: sanitizedMessages,
      };

      setParsedThread(markedThread);
    }

    sanitizeMessages();
  }, [thread]);

  if (!parsedThread) {
    return (
      <div className='relative size-full'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-6'>
      {parsedThread?.messages.map(message => (
        <Message message={message} key={message.id} />
      ))}
    </div>
  );
});
